import React, { useContext } from "react";
import Faq from "../components/Faq/Faq";
import Intro from "../components/Intro_v2/Intro_v2";
import Meta from "../components/Meta/Meta";
import Spacer from "../components/Spacer/Spacer";
import SectionTwoCol from "../components/SectionTwoCol/SectionTwoCol";
import { DataContext } from "../contexts/DataContext";
import { toast } from "react-toastify";
import KPI from "../components/KPI/KPI";
import useTrack from "../hooks/useTrack";
import { Helmet } from "react-helmet";
import useSnipcartEvents from "../hooks/useSnipcartEvents";
import Hero3BC from "../components/Hero3BC/Hero3BC";
import BusinessIconText from "../components/BusinessIconText/BusinessIconText";
import BusinessCards from "../components/BusinessCards/BusinessCards";

const Regali = () => {
  const { 
    pageLabels,
    currentLanguageSuffix,
    pageImages,
  } = useContext(DataContext);
  const { trackCustomEvent } = useTrack();
  toast.configure();
  useSnipcartEvents();

  return (
    <>
      <Meta />

      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify([
            {
              "@context":"https://schema.org",
              "@type": "Service",
              "name": pageLabels.hero.title.replace(/<[^>]*>?/gm, ""),
              "brand": {
                "@type": "Brand",
                "name": "3Bee",
              },
              "description": pageLabels.hero.paragraph.replace(/<[^>]*>?/gm, ""),
            },
          ])}
        </script>
      </Helmet>
      <Hero3BC
        title = {pageLabels.hero.title}
        image = {pageLabels.hero.image}
        paragraph = {pageLabels.hero.paragraph}
        buttonText = {pageLabels.hero.cta}
        to = "#video"
        titleBackground = "transparent"
        buttonVariant="orange"
        noNewsletter={true}
        lang={currentLanguageSuffix}
        allImages = {pageImages}
      />
      <Spacer size={10}/>
      <SectionTwoCol
        id="badgift"
        title={pageLabels.badgift.title}
        subtitle1={pageLabels.badgift.paragraph}
        images={["/2020/images/regali/badpresent_1.jpg", "/2020/images/regali/badpresent_old.jpg", "/2020/images/regali/badpresent_3.jpg", "/2020/images/regali/badpresent_girl.jpg"]}
      />
      
      <Spacer size={15}/>
      
      <Intro
        bigText={pageLabels.bees.title}
        subtitle={pageLabels.bees.subtitle}
        textAlign="center"
      />
      <BusinessIconText
        items={[{
          image: "/2020/images/business/regali/defence.svg",
          label: pageLabels.bees.impollination.label,
          text: pageLabels.bees.impollination.text,
        },{
          image: "/2020/images/business/regali/goccia.svg",
          label: pageLabels.bees.ecosystem.label,
          text: pageLabels.bees.ecosystem.text,
        },{
          image: "/2020/images/business/regali/tree.svg",
          label: pageLabels.bees.value.label,
          text: pageLabels.bees.value.text,
        },{
          image: "/2020/images/business/regali/terreno.svg",
          label: pageLabels.bees.bioindicators.label,
          text: pageLabels.bees.bioindicators.text,
        }]}
      />
    
      <Spacer size={10}/>
      <BusinessCards cards = {pageLabels.cards}/>
      <Spacer size={10}/>

      <KPI/>

      <Spacer size={10}/>

      <SectionTwoCol
        id={pageLabels.hive.id}
        title={pageLabels.hive.title}
        subtitle1={pageLabels.hive.paragraph}
        cta={pageLabels.hive.button}
        to="/regala-un-alveare"
        image="/2020/images/adotta/certificato_adotta.jpg"
        rightImage
        onClickCTA={() => {
          trackCustomEvent({
            category: "Service",
            action: "click",
            label: "Adotta un alveare",
          });
        }}
      />

      <Spacer size={10}/>

      <SectionTwoCol
        id={pageLabels.tree.id}
        title={pageLabels.tree.title}
        subtitle1={pageLabels.tree.paragraph}
        cta={pageLabels.tree.button}
        to="/adotta-un-albero"
        image="/2020/images/adotta/adotta-un-albero.png"
        onClickCTA={() => {
          trackCustomEvent({
            category: "Service",
            action: "click",
            label: "Adotta un albero",
          });
        }}
      />
      
      <Spacer size={10}/>

      <SectionTwoCol
        id={pageLabels.bee.id}
        title={pageLabels.bee.title}
        subtitle1={pageLabels.bee.paragraph}
        cta={pageLabels.bee.button}
        to="/adotta-un-ape"
        onClickCTA={() => {
          trackCustomEvent({
            category: "Service",
            action: "click",
            label: "Adotta un'ape",
          });
        }}
        image="/2020/images/adotta-un-ape/polly_mano.png"
        rightImage
      />

      <Spacer size={10}/>

      <Faq
        data={pageLabels.faq.dataFile.data}
      />
    </>
  );
};

export default Regali;
